import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Seo from "../shared/seo";
import Wrapper from "../components/Wrapper";

function Privacypolicy() {
  return (
    <Wrapper>
       <Seo
        title="Privacy Policy - bugs.work"
        description=
        "This is the privacy policy of bugs.work. It explains what personal data we collect, how we use it, and your rights regarding your data."
      />
    <div className="container mb-5">
      <div className="fnt-16">
        <h1 className="my-5 text-center fw-bold">Privacy Policy</h1>
        We are bugs.work and its affiliates (“We are <a className='pointer text-dark' href="https://bugs.work">bugs.work</a>,” bugs.work, “we,” “us,” or “our”) have prepared this Privacy Policy to explain what Personal Data (defined below) we collect, how we use and share that data, and your choices concerning our data practices. Through our software, (the “App”), we allow individuals (“Users” or “you”) to control your tools in a simple, fast and delightful way. The App, the chrome extension and <a className='pointer text-dark' href="https://bugs.work/app/">bugs.work</a> (the “Site”) are referred to collectively in this Privacy Policy as the “Service”.
        <br /><br />
        <h2>This Privacy Policy is incorporated into and forms part of our <a className='pointer text-dark' href="https://bugs.work/terms-of-use">Terms of Service</a></h2>.
        Before using the Service or submitting any Personal Data to bugs.work, please review this Privacy Policy carefully and contact us if you have any questions. By using the Service, you agree to the practices described in this Privacy Policy. If you do not agree to this Privacy Policy, please do not access the Site or otherwise use the Service.
        <br /><br />
        <strong>Personal data we collect</strong>
        <br /><br />
        We collect the following categories of information that alone or in combination with other information in our possession could be used to identify you (“Personal Data”):
        <br /><br />
        <strong>Account Data:</strong> If you create an account, we will collect the information needed to authenticate your access to the Service, including your email address.
        <br /><br />
        <strong>Financial Data:</strong> To the extent any features of the Service are available for a fee, we may collect the financial information necessary to process your payments, such as your payment card number and authentication details.
        <br /><br />
        <strong>Communication Data:</strong> We may collect information when you contact us with questions or concerns and when you voluntarily respond to questionnaires, surveys or requests for market research seeking your opinion and feedback. Providing this information is optional to you.
        <br /><br />
        <strong>Social Media Data:</strong> We have pages on social media sites like Facebook, Twitter, and LinkedIn (“Social Media Pages”). When you interact with our Social Media Pages, we will collect Personal Data that you elect to provide to us, such as your contact details. In addition, the companies that host our Social Media Pages may provide us with aggregate information and analytics regarding the use of our Social Media Pages.
        <br /><br />
        <strong>How we use personal data</strong>
        <br /><br />
        Under data protection law, we can only use personal data if we have a proper reason for doing so, for example:
        <br /><br />
        <ul>
          <li>to comply with our legal and regulatory obligations;</li>
          <li>for the performance of our Terms of Service with you;</li>
          <li>for our legitimate interests or those of a third party; or</li>
          <li>where you have given consent.</li>
        </ul>
        A legitimate interest is when we have a business or commercial reason to use personal data, so long as this is not overridden by your own rights and interests.
        <br /><br />
        We use Personal Data for the following purposes:
        <br /><br />
        <ul>
          <li>To process payments. <strong>Reason:</strong> For the performance of our Terms of Service with you</li>
          <li>To respond to your inquiries, comments, feedback, or questions.</li>
          <li>To send administrative information to you, for example, information regarding the Service and changes to our terms, conditions, and policies.</li>
          <li>To analyze how you interact with our Service. <strong>Reason:</strong> For our legitimate interests or those of a third party, i.e., to be as efficient as we can in our delivery of the Service to you</li>
          <li>To maintain and improve the content and functionality of the Service. <strong>Reason:</strong> For our legitimate interests or those of a third party, i.e., to enable us to improve our delivery of the Service to you</li>
          <li>To develop new products and services. <strong>Reason:</strong> For our legitimate interests or those of a third party, i.e., to enable us to improve our delivery of the Service to you</li>
          <li>To prevent fraud, criminal activity, or misuses of our Service, and to ensure the security of our IT systems, architecture, and networks. <strong>Reason:</strong> For our legitimate interests or those of a third party, i.e., to prevent and detect criminal activity that could be damaging for us and for you to comply with our legal and regulatory obligations</li>
          <li>To comply with legal obligations and legal processes. <strong>Reason:</strong> To comply with our legal and regulatory obligations</li>
          <li>To protect our rights, privacy, safety, or property, and/or that of our affiliates, you, or other third parties. <strong>Reason:</strong> For our legitimate interests or those of a third party, i.e., to protect our commercial interests and assets</li>
          <li>To tell you about products or services we believe may be of interest to you. <strong>Reason:</strong> For our legitimate interests or those of a third party, i.e., to promote our business to you</li>
        </ul>
        <strong>Aggregated Information</strong>
        <br /><br />
        We may aggregate Personal Data and use the aggregated information to analyze the effectiveness of our Service, to improve and add features to our Service, and for other similar purposes. In addition, from time to time, we may analyze the general behavior and characteristics of users of our Services and share aggregated information like general user statistics with prospective business partners. We may collect aggregated information through the Service, through cookies, and through other means described in this Privacy Policy.
        <br /><br />
        <strong>Marketing</strong>
        <br /><br />
        We may use your Personal Data to contact you to tell you about products or services we believe may be of interest to you. For instance, if you elect to provide your email or telephone number, we may use that information to send you special offers. We have a legitimate interest in processing your personal data for promotional purposes. This means we do not usually need your consent to send you promotional communications. However, where consent is needed (for example because you are based in the United Kingdom or European Union), we will ask for this consent separately and clearly. We will always treat your personal data with the utmost respect and never sell it to other organizations outside the bugs.work group for marketing purposes. You may opt out of receiving emails by following the instructions contained in each promotional email we send you. To the extent you create an account, you can also control the marketing emails and/or text messages you receive by updating your settings through your account. In addition, if at any time you do not wish to receive future marketing communications, you may contact us. If you unsubscribe from our marketing lists, you will no longer receive marketing communications, but we will continue to contact you regarding the management of your account, as applicable, other administrative matters, and to respond to your requests.
        <br /><br />
        <strong>Data retention policy</strong>
        <br /><br />
        We will not retain your personal data for longer than necessary for the purposes set out in this policy. Generally, we store data until it is no longer necessary to provide our services or until your account is deleted – whichever comes first.
        <br /><br />
        When it is no longer necessary to retain your personal data, we will delete or anonymize it. Even if we delete some or all of your personal information, we may continue to retain and use information that has been aggregated or anonymized so that it can no longer be used for personal identification.
        <br /><br />
        <strong>Update your information</strong>
        <br /><br />
        If you have created an account, you can log in to your account or contact us if you need to change or correct your Personal Data.
        <br /><br />
        <strong>Links to other websites</strong>
        <br /><br />
        The Service may contain links to other websites not operated or controlled by bugs.work, including social media services (“Third Party Sites”). The information that you share with Third Party Sites will be governed by the specific privacy policies and terms of service of the Third Party Sites and not by this Privacy Policy. By providing these links, we do not imply that we endorse or have reviewed these sites. Please contact the Third Party Sites directly for information on their privacy practices and policies.
        <br /><br />
        <strong>Security</strong>
        <br /><br />
        You use the Service at your own risk. We have appropriate security measures to prevent personal data from being accidentally lost, or used or accessed unlawfully. We limit access to your personal data to those who have a genuine business need to access it. Those processing your information will do so only in an authorized manner and are subject to a duty of confidentiality. We also have procedures in place to deal with any suspected data security breach. We will notify you and any applicable regulator of a suspected data security breach where we are legally required to do so.
        <br /><br />
        However, no Internet or e-mail transmission is ever fully secure or error-free. In particular, e-mail sent to or from us may not be secure. Therefore, you should take special care in deciding what information you send to us via the Service or e-mail. Please keep this in mind when disclosing any Personal Data to bugs.work via the Internet. In addition, we are not responsible for circumvention of any privacy settings or security measures contained on the Service, or third party websites.
        <br /><br />
        <strong>How to access, change and erase your personal information</strong>
        <br /><br />
        You can change some of your personal data through the account settings. In addition, if you want to access, correct, or delete your personal information you can submit a request by email with the subject line “Personal data request” to this email address: contact@bugs.work.
        <br /><br />
        <strong>Changes to privacy policy</strong>
        <br /><br />
        The Service and our business may change from time to time. As a result, we may change this Privacy Policy at any time. When we do, we will post an updated version on this page, unless another type of notice is required by the applicable law. By continuing to use our Service or providing us with Personal Data after we have posted an updated Privacy Policy, or notified you by other means if applicable, you consent to the revised Privacy Policy and practices described in it.
        <br /><br />
        <strong>Contact us</strong>
        <br /><br />
        <p>If you have any questions about our Privacy Policy or information practices, please feel free to contact us at our designated request address: contact@bugs.work.</p>
      </div>
    </div>
    </Wrapper>
  );
}

export default Privacypolicy;
